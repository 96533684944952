<template>
  <header :class="{ 'app-header': true, 'bgc-none': isHome }">
    <frameContent>
      <!-- 中部 -->
      <template #center>
        <div :class="{ 'header-center': true }">
          <div
            :class="['logo', isType ? 'logo-type' : '']"
            title="中科讯龙"
            @click="NavChange('Home')"
          ></div>
          <div class="header-nav">
            <div :class="['phone', isType ? 'phone-type' : '']">
              热线电话：020-32030256
            </div>
            <ul :class="['ul-tab', isType ? 'ul-tab-type' : '']">
              <li
                @click="isChange('Home')"
                :class="{ active: ActiveName === 'Home' }"
              >
                首页
                <i></i>
              </li>
              <!-- @click="isChange('Product')" -->
              <li
                :class="{
                  active:
                    ActiveName === 'Product' ||
                    ActiveName === 'Sports' ||
                    ActiveName === 'Park' ||
                    ActiveName === 'Campus' ||
                    ActiveName === 'Banner1',
                }"
                @click="isChange('Campus')"
              >
                解决方案
                <i></i>
                <ol>
                  <li @click.stop="isChange('Campus')" class="">
                    <div>
                      <h3>智慧校园解决方案</h3>
                      <span
                        >通过智慧校园AI系统，进行校园安防、访客管理等业务管理</span
                      >
                    </div>
                  </li>
                  <li @click.stop="isChange('Park')" class="">
                    <div>
                      <h3>智慧交通解决方案</h3>
                      <span
                        >保障交通安全、发挥交通基础设施效能、提高交通系统运行效率和管理水平</span
                      >
                    </div>
                  </li>
                  <li @click.stop="isChange('Sports')" class="">
                    <div>
                      <h3>智慧体育解决方案</h3>
                      <span
                        >推进全民健身计划，推动全民健身智慧化发展，推进“互联网+健身”</span
                      >
                    </div>
                  </li>
                </ol>
              </li>
              <li
                @click="isChange('Innovate')"
                :class="{ active: ActiveName === 'Innovate' }"
              >
                技术创新
                <i></i>
              </li>
              <li
                @click="isChange('About')"
                :class="{ active: ActiveName === 'About' }"
              >
                关于我们
                <i></i>
              </li>
              <li
                @click="isChange('Case')"
                :class="{ active: ActiveName === 'Case' }"
              >
                经典案例
                <i></i>
              </li>
              <li
                @click="isChange('Contact')"
                :class="{ active: ActiveName === 'Contact' }"
              >
                联系我们
                <i></i>
              </li>
            </ul>
          </div>
        </div>
      </template>
    </frameContent>
  </header>
</template>

<script>
import frameContent from '@/components/frameContent.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'Header',
  props: {
    isType: {
      typeof: Boolean,
      default: false
    }
  },
  components: {
    frameContent
  },
  computed: {
    ...mapGetters(['ActiveName', 'isHome'])
  },
  data () {
    return {
      // isHome: true
    }
  },
  methods: {
    // 切换tabs
    isChange (val) {
      const isHome =
        val === 'Home' ||
        val === 'Campus' ||
        val === 'Park' ||
        val === 'Sports'
      this.$store.commit('HomeChange', isHome)
      this.$store.commit('ActiveNameChange', val)
    },
    NavChange (val) {
      this.$store.commit('ActiveNameChange', val)
    }
  }
}
</script>

<style lang="less">
.app-header {
  position: sticky;
  z-index: 999;
  top: 0px;
  height: 106px;
  padding-top: 26px;
  background: linear-gradient(134deg, #fcfcfc 0%, #fafafa 100%);
  box-shadow: 0px 2px 4px 0px rgba(165, 165, 165, 0.2);
  .app-content {
    height: 80px;
  }
}
.bgc-none {
  background: transparent;
  background-color: transparent;
  box-shadow: none;
}
.logo {
  cursor: pointer;
  background-image: url("../assets/logo.png");
  background-repeat: no-repeat;
  background-size: 188px 68px;
  width: 188px;
  height: 68px;
  margin-bottom: 4px;
}
.logo-type {
  background-image: url("../assets/logo2.png");
}
.header-center {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .header-nav {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .phone {
      align-self: flex-end;
      font-size: 12px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
    }
    .phone-type {
      color: #f9f9f9;
    }
  }
}

.ul-tab {
  display: flex;
  align-items: flex-end;
  li {
    position: relative;
    margin-left: 48px;
    padding-bottom: 8px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    cursor: pointer;
    ol {
      display: none;
    }
    &:hover {
      font-weight: 600;
      ol {
        position: absolute;
        z-index: 1;
        width: 550px;
        height: 195px;
        text-align: left;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        top: 27px;
        background: #fafafa;
        padding: 16px 24px;
        li {
          flex: 1 1 100%;
          max-width: 200px;
          max-height: 51px;
          display: inline-block;
          text-align: left;
          margin: 0px;
          &:nth-child(2n) {
            margin-left: 65px;
          }
          &:nth-child(n + 3) {
            margin-top: 15px;
          }
          &:hover {
            h3,
            span {
              text-decoration: underline;
            }
          }
          h3 {
            font-weight: 500;
            color: rgba(0, 0, 0, 0.85);
            line-height: 20px;
          }
          span {
            display: inline-block;
            overflow: hidden;
            margin-top: 8px;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.45);
            line-height: 17px;
          }
        }
      }
    }
  }
  .active {
    font-size: 16px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
    i {
      z-index: 9999;
      left: -50%;
      position: absolute;
      transform: translate(50%, 0);
      bottom: 0;
      width: 100%;
      height: 1px;
      background: rgba(0, 0, 0, 0.85);
    }
  }
}
.ul-tab-type {
  li {
    margin-left: 48px;
    font-size: 14px;
    font-weight: 400;
    color: #f9f9f9;
  }
  .active {
    position: relative;
    font-size: 16px;
    font-weight: 600;
    color: #f9f9f9;
    i {
      z-index: 9999;
      left: -50%;
      position: absolute;
      transform: translate(50%, 0);
      bottom: 0;
      content: "";
      width: 100%;
      height: 1px;
      background: #f9f9f9;
    }
  }
}
</style>
